.desktop-playback-bar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 35px;
  padding: 0px 50px 5px 50px;
  transition: height 1s ease;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  max-width: 400px;

  ion-label {
    font-size: 12px;
    line-height: 30px;
    height: 30px;
  }

  ion-range {
    --height: 30px;
    --padding-start: 0;
    --knob-size: 15px;
    --knob-background: var(--ion-color-primary);
    --bar-background-active: var(--ion-color-primary);
    --bar-background: rgba(255, 179, 0, 0.3);

    &.range-disabled::part(knob)  {
      background: var(--ion-color-primary) !important;
      outline: var(--ion-color-primary) solid 5px;
      opacity: 0.9;
    }
  }
  
} 
