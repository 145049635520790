/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('poppins-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
       url('poppins-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('poppins-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('poppins-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('poppins-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('poppins-v12-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('poppins-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins Italic'), local('Poppins-Italic'),
       url('poppins-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('poppins-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('poppins-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('poppins-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('poppins-v12-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('poppins-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
       url('poppins-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('poppins-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('poppins-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('poppins-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('poppins-v12-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('poppins-v12-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
       url('poppins-v12-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('poppins-v12-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('poppins-v12-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('poppins-v12-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('poppins-v12-latin-600italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('poppins-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Poppins Bold'), local('Poppins-Bold'),
       url('poppins-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('poppins-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('poppins-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('poppins-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('poppins-v12-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('poppins-v12-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
       url('poppins-v12-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('poppins-v12-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('poppins-v12-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('poppins-v12-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('poppins-v12-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('poppins-v12-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Poppins Black'), local('Poppins-Black'),
       url('poppins-v12-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('poppins-v12-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('poppins-v12-latin-900.woff') format('woff'), /* Modern Browsers */
       url('poppins-v12-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('poppins-v12-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('poppins-v12-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
       url('poppins-v12-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('poppins-v12-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('poppins-v12-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('poppins-v12-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('poppins-v12-latin-900italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
