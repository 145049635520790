.player.desktop {
  background: var(--background-contrast-color);
  color: #fff;
  height: 95px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  border-top: 1px solid #262626;

  .controls-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: -5px;
  }
}
