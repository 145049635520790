.desktop.main-header {

  ion-toolbar {
    --background: var(--ion-color-primary);
    --color: #fff;
    --min-height: 56px;
    text-align: center;
  }

  .slot-center {
    position: absolute;
    left: calc(50% - 25px);
    top: 0;
    ion-buttons,
    ion-button {
      height: 56px !important;
      width: 56px !important;
      padding: 3px 0;
    }
    ion-icon {
      color: #fff;
      font-size: 50px;
    }
  }

  
  ion-button,
  ion-menu-button {
    --color: #fff;
  }

  ion-button ion-icon {
    color: #fff;
  }

  ion-menu-button {
    font-size: 26px;
  }

  .return-button {
    ion-icon {
      transform: rotate(180deg);
    }
  }

  .search-toolbar {
    position: absolute;
    top: 0;
    left: 0;
    --background: #1c1c1c; 
    transition: width 1s;
    width: 0;
    padding-top: var(--ion-safe-area-top, 0);
    &.active {
      width: 100%;
    }

    ion-input {
      border-radius: 25px;
      width: 95%;
      --background: var(--ion-background-color);
      --padding-start: 20px;
      --padding-end: 20px;
    }
  }
}
