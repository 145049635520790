.desktop-now-playing {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 10px;

  figure { 
    margin: 0;
    height: 60px;
    width: 60px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    background: #000;
  }

  ion-img {
    height: 60px;
    width: 100%;
    object-fit: cover;
  }

  figcaption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  p {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title {
    font-weight: 600;
    font-size: 14px;
  }
  .artist {
    font-weight: 400;
    font-size: 12px;
  }
}


/*
.now-playing {
  width: 100%;
  text-align: left;
  position: relative;
  top: 0;
  left: 0;
  height: 40px;
  overflow: hidden;
  display: grid;
  place-content: center;

  p {
    margin: 0;
    
    &.message {
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;
      text-align: center;
    }
    &.title {
      position: absolute;
      left: 0;
      top: 4px;
      font-size: 14px;
      line-height: 1.2;
      font-weight: 600;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.artist{
      position: absolute;
      left: 0;
      top: 22px;
      font-size: 11px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

}

@keyframes floatText {
  0% {
    left: 00%;
  }
  50% {
    left: -50%;
  }
  100% {
    left: 00%;
  }
}
*/
