.home-page {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  justify-content: space-between;

  .home-section {
    /* 
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid var(--ion-color-primary);
    border-radius: 10px; 
    */
    display: flex;
    height: auto;
    width: 98%;
    max-width: 1200px;
    padding: 20px;
    margin: 20px auto;
    flex-direction: column;
    justify-content: flex-start;
    align-content: space-around;
    align-items: flex-start;

    .cards-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      gap: 10px;

      ion-card {
        width: 100%;
        max-width: 380px;
        --background: #0d0d0d;
        --color: #fff;
        border: 1px solid var(--ion-color-primary);
        border-radius: 10px;
        ion-card-title {
          --color: #fff;
          font-weight: 600;
          display: flex;
          align-content: center;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
        }

        ul {
          margin: 0 auto;
          list-style-type: none; /* Remove bullets */
          padding: 0; /* Remove padding */

          li {
            border: 0 solid currentcolor;
            border-bottom-width: 1px;
            margin-bottom: 5px;
            padding-bottom: 5px;
            line-height: 18px;
            font-size: 16px;
            /* &:last-of-type {
              border-bottom-width: 0px;
            } */
          }
          p {
            display: flex;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
          }
          .error-message {
            font-weight: 600;
            span {
              color: red;
            }
          }
          a {
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            color: var(--ion-color-primary);
            display: block;
            width: auto;
            margin: 5px auto;
            text-align: center;
          }
        }
      }
    }
    

    h2 {
      display: block;
      margin: 0 auto 5px auto;
      text-align: center;
      font-weight: 600;
    }
  }
}
