
.dekstop-play-button.toogle-play {
  --background: #fff;
  --color: #000;
  --padding-end: 0;
  --padding-start: 0;
  width: 50px;
  height: 50px;
  font-size: 18px;
  .play-icon {
    margin-left: 3px;
  }
}
