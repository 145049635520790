body {
  a {
    color: inherit;
    font-size: inherit;
    text-decoration: none;
  }
}

ion-header {
  ion-button {
    width: 48px;
    height: 48px !important;
    margin: 0 !important;
    --border-radius: 50% !important;
    ion-icon {
      font-size: 26px;
    }
  }
}

.rounded-button {
  --background: #fff;
  --color: var(--ion-color-primary);
  --border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 0;
  ion-icon {
    font-size: 26px;
  }
}

.center-view-content {
  display: grid !important;
  place-items: center;
}

.flexible-width-content {
  display: block;
  width: 60%;
  margin: 0 auto;
}
@media (max-width: 599px) {
  .flexible-width-content {
    width: 90%;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .flexible-width-content {
    width: 80%;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  .flexible-width-content {
    width: 70%;
  }
}

.center-wrapper {
  width: 95%;
  max-width: 1080px;
  margin: 10px auto;
}

@media (max-width: 399px) {
  .center-wrapper {
    width: 97%;
  }
}

ion-grid {
  --ion-grid-padding: 0;
  --ion-grid-width-xs: 95%;
  --ion-grid-width-sm: 90%;
  --ion-grid-width-md: 700px;
  --ion-grid-width-lg: 900px;
  --ion-grid-width-xl: 1000px;
}
@media (min-width: 891px) and (max-width: 991px) {
  ion-grid {
    --ion-grid-width-md: 800px;
  }
}
@media (min-width: 1200px) and (max-width: 1335px) {
  ion-grid {
    --ion-grid-width-xl: 900px;
  }
}

.page-title {
  color: #fff;
  border: 0 solid var(--ion-color-primary);
  border-bottom-width: 2px;
  display: inline-block;
  letter-spacing: 1.5px;
  margin: 10px 0 15px 0;
  font-weight: 700;
  text-transform: uppercase;
  padding-right: 20px;
  padding-bottom: 5px;
  ion-skeleton-text {
    width: 200px;
    height: 25px;
    border-radius: 10px;
  }
  &.--media {
    border-color: var(--media_color_dark);
  }
  &.--news {
    border-color: var(--news_color_dark);
  }
}

.page-title-alt {
  color: var(--ion-color-primary);
  display: inline-block;
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0px;
  text-align: left;
  text-transform: uppercase;
  padding: 0 5px 5px 0;

  ion-skeleton-text {
    width: 200px;
    height: 25px;
    border-radius: 10px;
  }
  &.--media {
    color: var(--media_color);
  }
  &.--news {
    color: var(--news_color);
  }
}

ion-icon svg {
  pointer-events: none;
}

/* ----- Swiper ----- */
.swiper {
  margin: 0 auto;
  padding-bottom: 25px;
  &.swiper-horizontal {
    width: 95%;
    max-width: 1080px;
  }
}

.swiper-horizontal>.swiper-pagination-bullets {
  bottom: 0;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  transition: all 0.5s ease;
  opacity: 0.2;
  background: var(--ion-color-primary);
  &.swiper-pagination-bullet-active {
    width: 20px;
    border-radius: 40px;
    opacity: 1;
    background: var(--ion-color-primary);
  }
}
.swiper-horizontal.news-colors>.swiper-pagination-bullets .swiper-pagination-bullet {
  background: #fff;
  background: var(--news_color);
  &.swiper-pagination-bullet-active {
    width: 20px;
    border-radius: 40px;
    opacity: 1;
    
  }
  
}
.swiper-horizontal.media-colors>.swiper-pagination-bullets .swiper-pagination-bullet {
  background: #fff;
  background: var(--media_color);
  &.swiper-pagination-bullet-active {
    width: 20px;
    border-radius: 40px;
    opacity: 1;
    
  }
  
}

.swiper-button-prev {
  left: 5px;
}
.swiper-button-next {
  right: 5px;
}
.swiper-button-prev,
.swiper-button-next {
  width: 30px;
  height: 30px;
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.6);
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.6);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.6);
  &:after {
    font-weight: 700;
    font-size: 15px;
    color: #fff;
  }
}
/* ----- /Swiper ----- */

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

ion-breadcrumbs {
  ion-breadcrumb {
    font-weight: 700;
    --color: var(--ion-color-primary);
    --color-active: #000;
  
    &::part(native) {
      padding-right: 0;
      padding-left: 0;
    }
    &::part(separator) {
      margin-right: 5px;
      margin-left: 5px;
    }
    &.news {
      --color: var(--news_color);
    }
    &.breadcrumb-active {
      font-weight: 400;
      font-size: 0.9em;
      line-height: 16px;
    }
  }

  &.news {
    ion-breadcrumb {
      --color: var(--news_color_dark);
    }
  }
  &.media {
    ion-breadcrumb {
      --color: var(--media_color_dark);
    }
  }
}

p {
  margin-top: 0;
}

.error-toast {
  --background: var(--ion-color-danger);
  --color: #fff;
  font-weight: 600;
}

.app-divider {
  margin: 10px auto;
  width: 90%;
  background: var(--ion-color-primary);
}
