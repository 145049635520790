.full-screen-loader {
  opacity: 0;
  display: none;
  width: 100%;
  height: calc(100vh - 56px);
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.5s ease;

  &.loading {
    display: flex;
    opacity: 1;
  }
  ion-spinner {
    --color: var(--ion-color-primary);
    height: 60px !important;
    width: 60px !important;
  }
}
