.tv-page {
  ion-grid:last-of-type {
    margin-bottom: 30px;
  }

  hr {
    margin: 20px auto 10px auto;
  }

  h1 {
    margin: 10px 0;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  p {
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
    span {
      font-weight: 400;
    }
    &.error-message {
      color: red;
      margin-top: -15px;
    }
  }

  figure {
    margin: 0;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    img,
    ion-img {
      max-width: 300px;
    }
  }

  .embed-responsive {
    border: 1px solid var(--ion-color-primary);
  }
}
