.center-view-content {
  display: grid !important;
  place-items: center;
}
.flexible-width-content {
  display: block;
  width: 60%;
  margin: 0 auto;
}
@media (max-width: 599px) {
  .flexible-width-content {
    width: 90%;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .flexible-width-content {
    width: 80%;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  .flexible-width-content {
    width: 70%;
  }
}

.block1,
.block2,
.block3,
.block4,
.block5,
.block6,
.block7,
.block8,
.text-content,
.embed-content,
.image-content {
  display: block;
  width: 100%;
}

.embed-responsive-wrapper {
  position: relative;
  top: 0;
  left: 0;

  iframe[src*="anchor"] {
    width: 100%;
    height: auto;
  }

  twitter-widget,
  iframe,
  embed,
  object,
  video {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.simplebar-scrollbar::before {
  background-color: var(--ion-color-primary) !important;
}

.embed-responsive {
  position: relative;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  .fb_iframe_widget,
  iframe,
  embed,
  object,
  video,
  div[data-player] {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
  }
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

#clappr {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 320px;
  margin-bottom: 25px;
}
#clappr > div {
  width: 100%;
  height: 100%;
  position: absolute;
}

ol {
  padding-left: 25px;
}
