@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9nmomh');
  src:  url('fonts/icomoon.eot?9nmomh#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9nmomh') format('truetype'),
    url('fonts/icomoon.woff?9nmomh') format('woff'),
    url('fonts/icomoon.svg?9nmomh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter1:before {
  content: "\e93e";
}
.icon-error:before {
  content: "\e977";
}
.icon-error_outline:before {
  content: "\e978";
}
.icon-warningreport_problem:before {
  content: "\e979";
}
.icon-add_alert:before {
  content: "\e9fd";
}
.icon-notification_important:before {
  content: "\e9fe";
}
.icon-loopsync:before {
  content: "\e903";
}
.icon-mic1:before {
  content: "\e904";
}
.icon-mic_none:before {
  content: "\e905";
}
.icon-mic_off:before {
  content: "\e9ff";
}
.icon-moviemovie_creation:before {
  content: "\e9a9";
}
.icon-pause:before {
  content: "\e97a";
}
.icon-pause_circle_filled:before {
  content: "\e97b";
}
.icon-pause_circle_outline:before {
  content: "\e97c";
}
.icon-play_arrow:before {
  content: "\e97d";
}
.icon-play_circle_filled:before {
  content: "\e97e";
}
.icon-play_circle_outline:before {
  content: "\e97f";
}
.icon-playlist_add:before {
  content: "\ea00";
}
.icon-radio1:before {
  content: "\e980";
}
.icon-replay:before {
  content: "\e961";
}
.icon-skip_next:before {
  content: "\e981";
}
.icon-skip_previous:before {
  content: "\e982";
}
.icon-volume_down:before {
  content: "\e962";
}
.icon-volume_mute:before {
  content: "\e963";
}
.icon-volume_off:before {
  content: "\e964";
}
.icon-volume_up:before {
  content: "\e965";
}
.icon-fiber_new:before {
  content: "\ea01";
}
.icon-web_asset:before {
  content: "\e983";
}
.icon-fiber_pin:before {
  content: "\e984";
}
.icon-branding_watermark:before {
  content: "\e985";
}
.icon-call_to_action:before {
  content: "\e986";
}
.icon-featured_play_list:before {
  content: "\e987";
}
.icon-featured_video:before {
  content: "\e9fc";
}
.icon-4k:before {
  content: "\e9db";
}
.icon-chat:before {
  content: "\ea02";
}
.icon-comment:before {
  content: "\ea03";
}
.icon-emailmailmarkunreadlocal_post_office:before {
  content: "\e9aa";
}
.icon-location_onplaceroom:before {
  content: "\ea13";
}
.icon-message:before {
  content: "\ea18";
}
.icon-chat_bubble:before {
  content: "\ea19";
}
.icon-contact_phone:before {
  content: "\e988";
}
.icon-contact_mail:before {
  content: "\e989";
}
.icon-textsmssms:before {
  content: "\ea1a";
}
.icon-mail_outline:before {
  content: "\e966";
}
.icon-rss_feed:before {
  content: "\ea1b";
}
.icon-cell_wifi:before {
  content: "\ea1c";
}
.icon-list_alt:before {
  content: "\ea1d";
}
.icon-clearclose:before {
  content: "\e972";
}
.icon-content_copy:before {
  content: "\e967";
}
.icon-content_cut:before {
  content: "\e9ab";
}
.icon-content_paste:before {
  content: "\e9ac";
}
.icon-createmode_editedit:before {
  content: "\e9ad";
}
.icon-drafts:before {
  content: "\e9ae";
}
.icon-linkinsert_link:before {
  content: "\e968";
}
.icon-remove_circledo_not_disturb_on:before {
  content: "\e98a";
}
.icon-remove_circle_outline:before {
  content: "\e98b";
}
.icon-weekend:before {
  content: "\ea1e";
}
.icon-airplanemode_activeflightlocal_airport:before {
  content: "\ea1f";
}
.icon-bluetooth_connected:before {
  content: "\ea20";
}
.icon-bluetooth_searchingbluetooth_audio:before {
  content: "\ea21";
}
.icon-brightness_auto:before {
  content: "\ea22";
}
.icon-brightness_highbrightness_7:before {
  content: "\ea23";
}
.icon-brightness_lowbrightness_5:before {
  content: "\ea24";
}
.icon-brightness_mediumbrightness_6:before {
  content: "\ea25";
}
.icon-devicesphonelink:before {
  content: "\ea26";
}
.icon-usb:before {
  content: "\ea27";
}
.icon-wifi_tethering:before {
  content: "\ea28";
}
.icon-attach_file:before {
  content: "\ea04";
}
.icon-format_list_bulleted:before {
  content: "\ea05";
}
.icon-format_list_numbered:before {
  content: "\ea06";
}
.icon-format_list_numbered_rtl:before {
  content: "\ea07";
}
.icon-cloud_done:before {
  content: "\e98c";
}
.icon-cloud_download:before {
  content: "\e98d";
}
.icon-cloud_uploadbackup:before {
  content: "\e98e";
}
.icon-headset:before {
  content: "\ea29";
}
.icon-headset_mic:before {
  content: "\ea2a";
}
.icon-keyboard:before {
  content: "\ea2b";
}
.icon-keyboard_arrow_down:before {
  content: "\e98f";
}
.icon-keyboard_arrow_left:before {
  content: "\e990";
}
.icon-keyboard_arrow_right:before {
  content: "\e991";
}
.icon-keyboard_arrow_up:before {
  content: "\e992";
}
.icon-keyboard_voice:before {
  content: "\e969";
}
.icon-security:before {
  content: "\ea2c";
}
.icon-watch:before {
  content: "\ea2d";
}
.icon-audiotrack:before {
  content: "\e9af";
}
.icon-brightness_1:before {
  content: "\ea2e";
}
.icon-brightness_2:before {
  content: "\ea2f";
}
.icon-brightness_3:before {
  content: "\ea30";
}
.icon-brightness_4:before {
  content: "\ea31";
}
.icon-dehaze:before {
  content: "\e96a";
}
.icon-navigate_beforechevron_left:before {
  content: "\e973";
}
.icon-navigate_nextchevron_right:before {
  content: "\e974";
}
.icon-tune:before {
  content: "\ea32";
}
.icon-directions_bike:before {
  content: "\e9ca";
}
.icon-directions_bus:before {
  content: "\ea33";
}
.icon-directions_car:before {
  content: "\ea34";
}
.icon-directions_ferry:before {
  content: "\ea35";
}
.icon-directions_subwaydirections_transit:before {
  content: "\ea36";
}
.icon-directions_railway:before {
  content: "\ea37";
}
.icon-hotellocal_hotel:before {
  content: "\e9cb";
}
.icon-local_atm:before {
  content: "\e9b0";
}
.icon-local_attractionlocal_play:before {
  content: "\e9b1";
}
.icon-local_bar:before {
  content: "\e9b2";
}
.icon-local_cafefree_breakfast:before {
  content: "\e9b3";
}
.icon-local_gas_station:before {
  content: "\e9cc";
}
.icon-local_grocery_storeshopping_cart:before {
  content: "\e9b4";
}
.icon-local_laundry_service:before {
  content: "\ea38";
}
.icon-local_moviestheaters:before {
  content: "\e9cd";
}
.icon-local_offer:before {
  content: "\e9ce";
}
.icon-local_restaurantrestaurant_menu:before {
  content: "\ea08";
}
.icon-map:before {
  content: "\ea09";
}
.icon-fastfood:before {
  content: "\e9cf";
}
.icon-cancel:before {
  content: "\e975";
}
.icon-refresh:before {
  content: "\e9d0";
}
.icon-arrow_back_ios:before {
  content: "\e96b";
}
.icon-arrow_forward_ios:before {
  content: "\e96c";
}
.icon-wifi:before {
  content: "\ea39";
}
.icon-cake:before {
  content: "\ea3a";
}
.icon-share1:before {
  content: "\e993";
}
.icon-credit_cardpayment:before {
  content: "\ea3b";
}
.icon-done:before {
  content: "\e994";
}
.icon-explore:before {
  content: "\ea3c";
}
.icon-favorite:before {
  content: "\e995";
}
.icon-favorite_outline:before {
  content: "\e996";
}
.icon-help:before {
  content: "\e997";
}
.icon-highlight_remove:before {
  content: "\e976";
}
.icon-info:before {
  content: "\e998";
}
.icon-info_outline:before {
  content: "\e999";
}
.icon-http:before {
  content: "\ea3d";
}
.icon-event_seat:before {
  content: "\ea0a";
}
.icon-flight_takeoff:before {
  content: "\ea0b";
}
.icon-gif:before {
  content: "\ea3e";
}
.icon-fingerprint:before {
  content: "\ea3f";
}
.icon-done_outline:before {
  content: "\e99a";
}
.icon-cases:before {
  content: "\e9d1";
}
.icon-drive_file_rename_outline:before {
  content: "\e99b";
}
.icon-logout:before {
  content: "\e99c";
}
.icon-two_wheeler:before {
  content: "\ea40";
}
.icon-emoji_food_beverage:before {
  content: "\ea41";
}
.icon-emoji_events:before {
  content: "\ea0c";
}
.icon-emoji_objects:before {
  content: "\ea42";
}
.icon-sports_basketball:before {
  content: "\e9d2";
}
.icon-sports_cricket:before {
  content: "\e9d3";
}
.icon-sports_esports:before {
  content: "\e99d";
}
.icon-sports_football:before {
  content: "\ea43";
}
.icon-sports_motorsports:before {
  content: "\ea44";
}
.icon-sports_rugby:before {
  content: "\e9d4";
}
.icon-sports_soccer:before {
  content: "\e9d5";
}
.icon-sports:before {
  content: "\ea0d";
}
.icon-sports_volleyball:before {
  content: "\e99e";
}
.icon-sports_tennis:before {
  content: "\e99f";
}
.icon-bathtub:before {
  content: "\e9d6";
}
.icon-deck:before {
  content: "\e9d7";
}
.icon-king_bed:before {
  content: "\e9d8";
}
.icon-outdoor_grill:before {
  content: "\ea45";
}
.icon-sports_baseball:before {
  content: "\ea0e";
}
.icon-car_repair:before {
  content: "\e9a0";
}
.icon-dinner_dining:before {
  content: "\ea0f";
}
.icon-dry_cleaning:before {
  content: "\e9a1";
}
.icon-hardware:before {
  content: "\ea46";
}
.icon-liquor:before {
  content: "\e9a2";
}
.icon-lunch_dining:before {
  content: "\e9a3";
}
.icon-nightlife:before {
  content: "\e9a4";
}
.icon-park:before {
  content: "\ea47";
}
.icon-ramen_dining:before {
  content: "\e9a5";
}
.icon-theater_comedy:before {
  content: "\ea48";
}
.icon-badge:before {
  content: "\ea10";
}
.icon-icecream:before {
  content: "\ea11";
}
.icon-volunteer_activism:before {
  content: "\ea49";
}
.icon-contactless:before {
  content: "\ea4a";
}
.icon-delivery_dining:before {
  content: "\ea12";
}
.icon-brunch_dining:before {
  content: "\e9d9";
}
.icon-takeout_dining:before {
  content: "\ea4b";
}
.icon-ac_unit:before {
  content: "\e9da";
}
.icon-all_inclusive:before {
  content: "\ea4c";
}
.icon-business_center:before {
  content: "\e9b5";
}
.icon-casino:before {
  content: "\e9b6";
}
.icon-child_friendly:before {
  content: "\ea14";
}
.icon-fitness_center:before {
  content: "\e9b7";
}
.icon-golf_course:before {
  content: "\e9b8";
}
.icon-hot_tub:before {
  content: "\ea15";
}
.icon-kitchen:before {
  content: "\ea16";
}
.icon-pool:before {
  content: "\e9b9";
}
.icon-room_service:before {
  content: "\e9ba";
}
.icon-smoke_free:before {
  content: "\e9bb";
}
.icon-smoking_rooms:before {
  content: "\e9bc";
}
.icon-goat:before {
  content: "\ea17";
}
.icon-at-sign:before {
  content: "\e9be";
}
.icon-edit-2:before {
  content: "\e9a6";
}
.icon-film:before {
  content: "\e9bf";
}
.icon-globe:before {
  content: "\e9c0";
}
.icon-log-in:before {
  content: "\e9a7";
}
.icon-log-out:before {
  content: "\e9a8";
}
.icon-menu1:before {
  content: "\e96d";
}
.icon-mic-off:before {
  content: "\e96e";
}
.icon-mic2:before {
  content: "\e96f";
}
.icon-settings:before {
  content: "\e9c1";
}
.icon-share-2:before {
  content: "\e970";
}
.icon-sliders:before {
  content: "\e9c2";
}
.icon-user-check:before {
  content: "\e9c3";
}
.icon-user-minus:before {
  content: "\e9c4";
}
.icon-user-plus:before {
  content: "\e9c5";
}
.icon-user-x:before {
  content: "\e9c6";
}
.icon-user:before {
  content: "\e9c7";
}
.icon-users:before {
  content: "\e9c8";
}
.icon-video:before {
  content: "\e9c9";
}
.icon-ra-guitar:before {
  content: "\ea55";
}
.icon-ra-guitar-disconected:before {
  content: "\ea56";
}
.icon-ra-love:before {
  content: "\ea57";
}
.icon-ra-love-disconected:before {
  content: "\ea58";
}
.icon-ra-pause:before {
  content: "\ea59";
}
.icon-ra-play:before {
  content: "\ea5a";
}
.icon-ra-tropical:before {
  content: "\ea5b";
}
.icon-ra-tropical-disconected:before {
  content: "\ea5c";
}
.icon-ra-urban:before {
  content: "\ea5d";
}
.icon-ra-urban-disconected:before {
  content: "\ea5e";
}
.icon-ra-radio-disconected:before {
  content: "\e918";
}
.icon-ra-guitar-c:before {
  content: "\e919";
}
.icon-ra-guitar-disconected-c:before {
  content: "\e91a";
}
.icon-ra-login:before {
  content: "\e91b";
}
.icon-ra-love-c:before {
  content: "\e91c";
}
.icon-ra-love-disconected-c:before {
  content: "\e935";
}
.icon-ra-menu:before {
  content: "\e936";
}
.icon-ra-pause-c:before {
  content: "\e937";
}
.icon-ra-play-c:before {
  content: "\e938";
}
.icon-ra-tropical-c:before {
  content: "\e939";
}
.icon-ra-tropical-disconected-c:before {
  content: "\e93a";
}
.icon-ra-urban-c:before {
  content: "\e93b";
}
.icon-ra-urban-disconected-c:before {
  content: "\e93c";
}
.icon-ra-radio:before {
  content: "\e93d";
}
.icon-c-googlemaps:before {
  content: "\e917";
}
.icon-facebook1:before {
  content: "\e90a";
  color: #1877f2;
}
.icon-google1:before {
  content: "\e914";
  color: #4285f4;
}
.icon-googlehangouts:before {
  content: "\e907";
  color: #0c9d58;
}
.icon-googlepay:before {
  content: "\e908";
  color: #5f6368;
}
.icon-googleplay:before {
  content: "\e909";
  color: #607d8b;
}
.icon-instagram1:before {
  content: "\e915";
  color: #e4405f;
}
.icon-linkedin:before {
  content: "\e90b";
  color: #0077b5;
}
.icon-medium:before {
  content: "\e90c";
}
.icon-paypal1:before {
  content: "\e90d";
  color: #00457c;
}
.icon-twitch1:before {
  content: "\e90e";
  color: #6441a4;
}
.icon-twitter1:before {
  content: "\e90f";
  color: #1da1f2;
}
.icon-uber:before {
  content: "\e910";
}
.icon-waze:before {
  content: "\e911";
  color: #333665;
}
.icon-whatsapp1:before {
  content: "\e912";
  color: #25d366;
}
.icon-youtube1:before {
  content: "\e913";
  color: #f00;
}
.icon-xp-arrow-right:before {
  content: "\e9dc";
}
.icon-xp-bars:before {
  content: "\e9dd";
}
.icon-xp-bed:before {
  content: "\e9de";
}
.icon-xp-bed-b:before {
  content: "\e9df";
}
.icon-xp-beer:before {
  content: "\e9e0";
}
.icon-xp-beer-b:before {
  content: "\e9e1";
}
.icon-xp-clock:before {
  content: "\e9e2";
}
.icon-xp-clock-b:before {
  content: "\e9e3";
}
.icon-xp-coffee:before {
  content: "\e9e4";
}
.icon-xp-coffee-b:before {
  content: "\e9e5";
}
.icon-xp-dont-disturb:before {
  content: "\e9e6";
}
.icon-xp-dont-disturb-b:before {
  content: "\e9e7";
}
.icon-xp-events:before {
  content: "\e9e8";
}
.icon-xp-events-b:before {
  content: "\e9e9";
}
.icon-xp-events-c:before {
  content: "\e9ea";
}
.icon-xp-heart:before {
  content: "\e9eb";
}
.icon-xp-heart-b:before {
  content: "\e9ec";
}
.icon-xp-megaphone:before {
  content: "\e9ed";
}
.icon-xp-megaphone-b:before {
  content: "\e9ee";
}
.icon-xp-megaphone-c:before {
  content: "\e9ef";
}
.icon-xp-search:before {
  content: "\e9f0";
}
.icon-xp-share:before {
  content: "\e9f1";
}
.icon-xp-spot:before {
  content: "\e9f2";
}
.icon-xp-spot-b:before {
  content: "\e9f3";
}
.icon-xp-spot-c:before {
  content: "\e9f4";
}
.icon-xp-star:before {
  content: "\e9f5";
}
.icon-xp-star-b:before {
  content: "\e9f6";
}
.icon-xp-toast:before {
  content: "\e9f7";
}
.icon-xp-toast-b:before {
  content: "\e9f8";
}
.icon-xp-user:before {
  content: "\e9f9";
}
.icon-xp-utensils:before {
  content: "\e9fa";
}
.icon-xp-utensils-b:before {
  content: "\e9fb";
}
.icon-radio:before {
  content: "\e902";
}
.icon-play-b:before {
  content: "\e901";
}
.icon-search-colored .path1:before {
  content: "\e91d";
  color: rgb(32, 171, 204);
}
.icon-search-colored .path2:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(153, 231, 255);
}
.icon-search-colored .path3:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(9, 146, 168);
}
.icon-search-colored .path4:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(117, 204, 219);
  opacity: 0.8;
}
.icon-search-colored .path5:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(117, 204, 219);
  opacity: 0.8;
}
.icon-search-colored .path6:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(117, 204, 219);
  opacity: 0.8;
}
.icon-search-colored .path7:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(249, 109, 17);
}
.icon-search-colored .path8:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(221, 80, 10);
}
.icon-search-colored .path9:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(163, 214, 78);
}
.icon-search-colored .path10:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(137, 165, 58);
}
.icon-search-colored .path11:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(160, 111, 188);
}
.icon-search-colored .path12:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(122, 93, 155);
}
.icon-search-colored .path13:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(157, 110, 179);
}
.icon-search-colored .path14:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(143, 95, 170);
}
.icon-search-colored .path15:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path16:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path17:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path18:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path19:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(97, 50, 133);
}
.icon-search-colored .path20:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(76, 38, 109);
}
.icon-search-colored .path21:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(219, 56, 9);
}
.icon-search-colored .path22:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(193, 43, 8);
}
.icon-search-colored .path23:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(163, 214, 78);
}
.icon-search-colored .path24:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(137, 165, 58);
}
.icon-search-colored .path25:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(249, 109, 17);
}
.icon-search-colored .path26:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(221, 80, 10);
}
.icon-search-colored .path27:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(32, 171, 204);
}
.icon-search-colored .path28:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(14, 139, 155);
}
.icon-search-colored .path29:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(32, 171, 204);
}
.icon-search-colored .path30:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(14, 139, 155);
}
.icon-search-colored .path31:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(160, 111, 188);
}
.icon-search-colored .path32:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(127, 93, 160);
}
.icon-search-colored .path33:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path34:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(163, 214, 78);
}
.icon-search-colored .path35:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path36:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path37:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path38:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path39:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path40:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path41:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path42:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path43:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path44:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-search-colored .path45:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(21, 7, 48);
}
.icon-yok-hat-colored .path1:before {
  content: "\e956";
  color: rgb(157, 110, 179);
}
.icon-yok-hat-colored .path2:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(32, 171, 204);
}
.icon-yok-hat-colored .path3:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(249, 109, 17);
}
.icon-yok-hat-colored .path4:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(219, 56, 9);
}
.icon-yok-hat-colored .path5:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(97, 50, 133);
}
.icon-yok-hat-colored .path6:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(163, 214, 78);
}
.icon-yok-hat-colored .path7:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(157, 110, 179);
}
.icon-yok-hat-colored .path8:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(32, 171, 204);
}
.icon-yok-hat-colored .path9:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(249, 109, 17);
}
.icon-yok-hat-colored .path10:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(163, 214, 78);
}
.icon-yok-hat-colored .path11:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(163, 214, 78);
}
.icon-live:before {
  content: "\e900";
}
.icon-play:before {
  content: "\1f318";
}
.icon-search:before {
  content: "\e906";
}
.icon-share:before {
  content: "\e933";
}
.icon-yok-hat:before {
  content: "\e934";
}
.icon-xp-waze:before {
  content: "\e916";
}
.icon-mic:before {
  content: "\e971";
}
.icon-barcode:before {
  content: "\ea4d";
}
.icon-qrcode:before {
  content: "\ea4e";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-google:before {
  content: "\ea88";
}
.icon-hangouts:before {
  content: "\ea8e";
}
.icon-google-drive:before {
  content: "\ea8f";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-spotify:before {
  content: "\ea94";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-twitch:before {
  content: "\ea9f";
}
.icon-github:before {
  content: "\eab0";
}
.icon-blogger:before {
  content: "\eab7";
}
.icon-tumblr:before {
  content: "\eab9";
}
.icon-soundcloud:before {
  content: "\eac3";
}
.icon-linkedin2:before {
  content: "\eaca";
}
.icon-stumbleupon:before {
  content: "\eace";
}
.icon-stackoverflow:before {
  content: "\ead0";
}
.icon-pinterest2:before {
  content: "\ead2";
}
.icon-paypal:before {
  content: "\ead8";
}
.icon-chrome:before {
  content: "\ead9";
}
.icon-plus:before {
  content: "\ea4f";
}
.icon-minus:before {
  content: "\ea50";
}
.icon-cross:before {
  content: "\ea51";
}
.icon-checkmark:before {
  content: "\ea52";
}
.icon-filter:before {
  content: "\ea5f";
}
