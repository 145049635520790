.home-section.icecast-souces-resume {
  .cards-container {
    ion-card {
      max-width: 300px !important;

      p {
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        flex-direction: column !important;
        gap: 5px;
        font-weight: 600;
        span {
          font-weight: 400;
        }
      }
    }
    .card-actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      ion-button {
        font-size: 20px;
        height: 40px;
        width: 40px;
        --padding-start: 0;
        --padding-end: 0;
      }
    }
  }
}
